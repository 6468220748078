
import { defineComponent, PropType } from "vue";
import { ThemeCard } from "@/models";

export default defineComponent({
    props: {
        theme: {
            type: Object as PropType<ThemeCard>,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ["change"],
    setup(props, { emit }) {
        const handleChange = (): void => {
            if (!props.theme.selected && !props.loading) {
                emit("change", props.theme);
            }
        };

        return { handleChange, textClass: `text-${props.theme.type}` };
    }
});
