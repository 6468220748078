
import { computed, defineComponent, reactive, ref } from "vue";
import { ProfileModel, ThemeCard } from "@/models";
import { getThemes, loadUserDependentData, mapProfile } from "@/helpers";

import { routeNames } from "@/router/routeNames";
import { useRouter } from "vue-router";

import { useStore } from "@/store";
import { AppActionTypes } from "@/store/actions";

import {
    getModelStateFromResponse,
    hasErrors,
    ValFormInput,
    ValSummary
} from "@elite/validation";
import { Form, SubmissionContext } from "vee-validate";

import { Dialog } from "@progress/kendo-vue-dialogs/dist/npm/Dialog";

import PageGreetingVue from "@/components/PageGreeting.vue";
import ThemeCardVue from "@/components/ThemeCard.vue";
import ProfileImageVue from "@/components/ProfileImage.vue";
import { msalService } from "@/authentication";

export default defineComponent({
    name: "profile-view",
    components: {
        "val-form-input": ValFormInput,
        "val-summary": ValSummary,
        "v-form": Form,
        "page-greeting": PageGreetingVue,
        "theme-card": ThemeCardVue,
        "kendo-modal": Dialog,
        "profile-image": ProfileImageVue
    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const { state, getters, dispatch } = store;

        const model = reactive<ProfileModel>(mapProfile(state.user, {}));

        const errors = ref<string[]>([]);

        const updateProfile = async (
            values: ProfileModel,
            context: SubmissionContext
        ): Promise<void> => {
            errors.value = [];

            const response = await dispatch(
                AppActionTypes.updateProfile,
                model
            );

            if (hasErrors(response)) {
                const modelState = getModelStateFromResponse(response, context);
                errors.value = modelState.modelErrors;
                return;
            }

            await router.push({ name: routeNames.home });
        };

        const updatePassword = async (): Promise<void> => {
            await msalService.resetPassword();

            await router.push({ name: routeNames.home });
        };

        // Profile image
        const showModal = ref(false);
        const hideModal = (): void => {
            showModal.value = false;
        };

        const profileImageSource = ref<string | null>(null);
        const profileImageItems = computed(
            () => state.settings?.profileImageItems
        );

        const updateProfileImage = (value: string | undefined | null): void => {
            if (!value) {
                return;
            }
            const imageId = parseInt(value, 10);
            if (imageId) {
                model.profileImageId = imageId;
                const previewSource = profileImageItems.value?.find(
                    (x) => x.value === value
                )?.text;

                if (previewSource) {
                    profileImageSource.value = previewSource;
                }
            }

            hideModal();
        };

        // Theme selector
        const themeCards = ref<Array<ThemeCard> | null>(null);
        const themeLoading = ref(false);
        const handleThemeChange = async (payload: ThemeCard): Promise<void> => {
            themeLoading.value = true;
            themeCards.value?.forEach(
                (theme) => (theme.selected = theme.type === payload.type)
            );

            await dispatch(AppActionTypes.updateUserTheme, payload.type);
            themeLoading.value = false;
        };

        // Page greeting
        const title = "My Profile 🤸";
        const message =
            "Set your profile picture and pick a theme to make it your own.";

        loadUserDependentData(store, () => {
            mapProfile(state.user, model);

            profileImageSource.value = model.profileImageUrl || null;

            themeCards.value = getThemes(model.theme);
        });

        return {
            model,
            rules: computed(() =>
                getters.getModelValidationRules("profileModel")
            ),
            loading: computed(() => getters.loading),
            cancelRouteName: routeNames.home,
            updateProfile,
            updatePassword,
            title,
            message,
            handleThemeChange,
            themeCards,
            themeLoading,
            showModal,
            hideModal,
            updateProfileImage,
            profileImageItems,
            profileImageSource,
            errors,
            isStudent: computed(() => store.getters.isStudent)
        };
    }
});
